import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import { FaPlay, FaPlayCircle } from 'react-icons/fa';
import {CasinoGamesCardStyled} from './casinoGamesCardStyled'




const CasinoGamesCard = () => {
    const handleClickReadmore = (idRef) => {
        const textContentFul = document.getElementsByClassName('textContentFul')
        const readMore = document.getElementsByClassName('readMore')

        for(let i = 0; i < textContentFul.length; i++) {
            if(textContentFul[i].id === idRef && textContentFul[i].childNodes[1].classList[0] !== 'show') {
                textContentFul[i].childNodes[1].classList.add("show");
                readMore[i].innerHTML = '<i>read less<i>'

            } else if (textContentFul[i].id === idRef && textContentFul[i].childNodes[1].classList[0] === 'show'){
                textContentFul[i].childNodes[1].classList.remove("show");
                readMore[i].innerHTML = '<i>read more...<i>'
            }

        }
    }

    const data = useStaticQuery(graphql`
    query CasinoGamesCardQuery {
        allContentfulCasinoGames(sort: {fields: orderNumber}) {
            edges {
              node {
                gameDeatail {
                  gameDeatail
                }
                id
                name
                gameId
                image {
                  sizes(quality: 90, maxHeight: 1000) {
                    aspectRatio
                    base64
                    src
                    srcSet
                    ...GatsbyContentfulSizes_withWebp
                  }
                }
              }
            }
          }
    }
`)
    if(!data.allContentfulCasinoGames.edges) {
        return <div>Loading...</div>
    }
    return (
        data.allContentfulCasinoGames.edges.map((edge) => {
            if(edge.node && edge.node.image.sizes)
                {
                    return <CasinoGamesCardStyled key={edge.node.id}>
                                <div className="contentWrap" >
                                    <div className="imageWrap">
                                        <Img alt={edge.node.name} sizes={edge.node.image.sizes}/>
                                        <a href={`/games/${edge.node.gameId}/`}
                                        className="linkWrap" target="_blank" rel="noopener noreferrer">
                                            <FaPlayCircle className="playIcon" />
                                            <p className="playDemo">Demo</p>
                                        </a>
                                    </div>
                                    <div className="textWrap">
                                        <h3 className="title">{edge.node.name}</h3>
                                        <p className="text">
                                            <span className="textContentFul" id={edge.node.id}
                                            dangerouslySetInnerHTML={{ __html:edge.node.gameDeatail.gameDeatail }}></span>
                                        </p>
                                        <p className="readMore"
                                        onClick={handleClickReadmore.bind(this, edge.node.id)}><i>read more...</i></p>
                                        <a href={`/games/${edge.node.gameId}/`}
                                                className="playDemoButton" target="_blank" rel="noopener noreferrer">
                                            <FaPlay />
                                            Demo
                                        </a>
                                    </div>
                                </div>
                            </CasinoGamesCardStyled>

                }
                return null
            }
        )
    )
}

export default CasinoGamesCard



