import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device } from '../styles/device';


export const FeatureStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    margin-bottom: 40px;
    align-items: center;

    @media ${device.mobile} {
        margin-bottom: 20px;
    }

    @media ${device.tablet} {
        padding: 0 30px;
    }

    @media ${device.mobile} {
        padding: 0 5px;
        margin-bottom: 0;
    }

    .imageWrap {
        max-width: 120px;
        margin-bottom: 20px;
    }

    .gatsby-image-wrapper {
        cursor: pointer;
        text-align: center;
        width: 120px;

        &:hover {
            transition: background-color .3s, color .3s;
        }

        img {
            object-fit: contain !important;
        }
    }

    .text {
        text-align: center;
        margin-top: 10px;

        @media ${device.mobile} {
                text-align: center;
            }

        .title {
            text-transform: uppercase;
            color: ${palette.white};
            margin-bottom: 10px;
            text-align: center;
            font-family: 'bebas-neue', sans-serif;
            font-size: ${palette.fontSizeLg};
            font-weight: ${palette.fontWeightTitle};

            @media ${device.mobile} {
                color: ${palette.white};
                text-align: center;
            }
        }

        p.subTitle {
            font-size: ${palette.fontSizeMd};
            line-height: ${palette.lineHeightSm};
            margin-bottom: 0;
            color: ${palette.lightGrey};
        }
    }

`;