import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device } from '../styles/device'



export const CasinoGamesCardStyled = styled.div`
    margin: 1rem;
    flex-grow: 1;
    flex-basis: 45%;

    @media ${device.tablet} {
        flex-basis: 28%;
    }

    .contentWrap {
        border-radius: 30px 30px 0 0;

    }

    .imageWrap {
        position: relative;

        &:hover > .linkWrap {
            margin-top: 0;
            height: 100%;
        }
    }

    .linkWrap {
        border-radius: 10px;
        position: absolute;
        top: 0;
        width: 100%;
        height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        transition: .2s ease-in-out;
        margin-top: 100%;
        background-color: rgba(0,0,0, .7);
        overflow: hidden;
        text-decoration: none;

        .playIcon {
            color: ${palette.white};
            height: 40%;
            width: 100%;
        }

        .playDemo {
            color: ${palette.white};
            font-family: 'bebas-neue', sans-serif;
            font-size: ${palette.fontSizeXl};
            margin-top: 1rem;
        }
    }

    .gatsby-image-wrapper {
        border-radius: 10px 10px 0 0;

        img {
            object-fit: contain !important;
        }
    }

    .textWrap {
        align-items: center;
        padding: 1rem;
        background-color: ${palette.white};
        border-radius: 0 0 10px 10px;


        .title {
            color: ${palette.black};
            text-align: left;
            font-size: ${palette.fontSizeMd};
            text-transform: uppercase;
            margin-top: 1.2rem;
            margin-bottom: 1rem;
        }

        .text {
            color: ${palette.black};
            text-align: left;
            font-size: ${palette.fontSizeSm};
            display: block;
            margin-bottom: 0;

            .textContentFul {

                >span {
                    display: none;

                    &.show {
                        display: inline;
                    }
                }

            }
        }

        .readMore {
            cursor: pointer;
            font-weight: 500;
            font-size: ${palette.fontSizeSm};

        }

        .playDemoButton {
            display: flex;
            align-items: center;
            color: ${palette.black};
            font-family: 'bebas-neue', sans-serif;
            font-size: ${palette.fontSizeMd};
            font-weight: 500;
            opacity: 1;
            margin: 1rem auto;
            border-radius: 40px;

            svg {
                margin-right: 0.8rem;
            }
        }

    }


`;

