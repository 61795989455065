import React from 'react'
import {FeatureStyled} from './featureStyled'

const FeatureBlock = ({node}) => {
    return <FeatureStyled>
            <div className="imageWrap">
                <img src={node.image && node.image.file.url} alt={node.title} />
            </div>
            <div className="text">
                <h3 className="title">{node.title}</h3>
                <p className="subTitle">
                {node.text}
                </p>
            </div>
        </FeatureStyled>
}

export default FeatureBlock