import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'
import circles from '../images/circles.svg'



export const OurGamesStyled = styled.div`
    background-color: ${palette.lightGreyLine};
    background-image: url(${circles});
    padding: 8rem 15px;

    .container {
        max-width: ${width.xl};
        margin: 0 auto;

        .gameCardWrap {
            display: flex;
            flex-wrap: wrap;

            .contentWrap {
                flex-grow: 1;
                flex-basis: 30%;
            }
        }

        >.title {
        color: ${palette.black};
        font-size: ${palette.fontSizeXl};
        font-weight: ${palette.fontWeightTitle};
        font-family: 'bebas-neue', sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5rem;

        @media ${device.tablet} {
            font-size: ${palette.fontSizeTitle};
        }

        &:before {
            content: '';
            background-size: 100% 100%;
            display: inline-block;
            height: 50px;
            width:50px;
            margin-right: 30px;
        }

        &:after {
            content: '';
            background-size: 100% 100%;
            display: inline-block;
            height: 50px;
            width:50px;
            margin-left: 30px;
        }
    }



    }

`;

