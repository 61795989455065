import React  from "react"
import CasinoGamesCard from './casinoGamesCard'
import {OurGamesStyled} from './ourGamesStyled'


const OurGames = () => {
    return (
       <OurGamesStyled>
           <div className="container">
                <h2 className="title">Our Games</h2>
                <div className="gameCardWrap">
                    <CasinoGamesCard />
                </div>
           </div>

       </OurGamesStyled>
    )
}


export default OurGames



