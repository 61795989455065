import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import Hero from '../components/hero'
import Title from '../components/title'
import FeatureCasino from '../components/featureCasino'
import OurGames from '../components/ourGames'
import License from '../components/license'
import TitleMainStyled from './styles/titleMainStyled'



class Casino extends Component {
    render() {
        if(!this.props.data.allContentfulTitle.edges) {
            return <div>Loading...</div>
        }
        const descriptionMeta = this.props.data.allContentfulTitle.edges[0].node.text
        return (
            <Layout>
                <SEO title="Casino" description={descriptionMeta} />
                <Hero position="Casino"
                    buttonText="Contact us"
                    classButton="casinoCta"
                    bannerImage={true}
                    noBackgroundHero={true}
                    className="casinoHero">
                </Hero>
                <TitleMainStyled twoColumns={true}>
                <div className="container">
                    <Title position="Casino" className="twoColumns casino" />
                    <div className="featureWrap casino">
                        <FeatureCasino />
                    </div>
                </div>
            </TitleMainStyled>
            <OurGames />
            <License />
            </Layout>
        )
    }
}

export default Casino

export const pageQuery = graphql`
    query CasinoPageQuery {
        allContentfulTitle(filter: {position: {eq: "Casino"}}) {
            edges {
                node {
                    text
                }
            }
        }
    }
`